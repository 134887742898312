import request from "../utils/request";

/* 菜单删除 */
export const menuDelete = (id) => {
    return request.post(`/back/t/menu/deleteById/${id}`)
}

/* 菜单无效 */
export const menuDisable = (id, enable) => {
    return request.post(`/back/t/menu/disableById/${id}/${enable}`)
}

/* 菜单新增 */
export const menuAdd = (params) => {
    return request.post('/back/t/menu/insert', params)
}

/* 列表查询 */
export const menuList = (params) => {
    return request.get('/back/t/menu/list', params)
}

/* 菜单更新 */
export const menuUpdate = (params) => {
    return request.post('/back/t/menu/update', params)
}