<template>
  <div class="menu-manage">
    <div class="search-area">
      <el-form size="small" :inline="true" :model="formSearch" class="">
        <el-form-item label="菜单名称：">
          <el-input
            v-model="formSearch.name"
            placeholder="请输入菜单名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单备注：">
          <el-input
            v-model="formSearch.remarks"
            placeholder="请输入菜单备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单链接：">
          <el-input
            v-model="formSearch.url"
            placeholder="请输入菜单链接"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-show="authorities.includes('BACK:BACK_T_MENU_PAGE_LIST')" type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="opt-area">
      <el-button
        v-show="authorities.includes('BACK:BACK_T_MENU_ADD')"
        size="small"
        @click="handleCreateMenu"
        type="primary"
        icon="el-icon-plus"
        >创建菜单</el-button
      >
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:BACK_T_MENU_PAGE_LIST')"
        height="100%"
        :data="searchList"
        v-loading="loading"
        row-key="id"
        style="width: 100%; min-width: 1400px"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column
          prop="name"
          align="center"
          label="菜单名称"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注">
        </el-table-column>
        <el-table-column prop="url" align="center" label="菜单链接">
        </el-table-column>
        <el-table-column prop="authority" align="center" label="权限标识">
        </el-table-column>
        <el-table-column prop="sort" align="center" label="排序" width="80">
        </el-table-column>
        <el-table-column prop="enable" align="center" label="状态" width="80">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" v-if="scope.row.enable == 1">有效</el-link>
            <el-link :underline="false" v-else type="danger">无效</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTimeString"
          align="center"
          label="更新时间"
        >
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-link
              v-show="authorities.includes('BACK:BACK_T_MENU_UPDATE')"
              type="primary"
              :underline="false"
              @click="handleEditMenu(scope.row)"
              >修改</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:BACK_T_MENU_DELETE')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleDeleteMenu(scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="dialogAddMenuTitle"
      :visible.sync="dialogAddMenu"
      width="500px"
    >
      <div>
        <el-form
          ref="saveMenuData"
          size="small"
          :model="saveMenuData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="菜单名称：" prop="name">
            <el-input
              v-model="saveMenuData.name"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="功能链家：" prop="url">
            <el-input v-model="saveMenuData.url"></el-input>
          </el-form-item>
          <el-form-item label="权限标识：" prop="authority">
            <el-input v-model="saveMenuData.authority"></el-input>
          </el-form-item>
          <el-form-item label="菜单：" prop="pid">
            <treeselect
              v-model="saveMenuData.pid"
              :options="originList"
              @input="handleCloseTree"
              placeholder="请选择选择菜单"
            />
          </el-form-item>
          <el-form-item label="菜单icon：">
            <el-input v-model="saveMenuData.icon"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              maxlength="200"
              show-word-limit
              type="textarea"
              :rows="2"
              resize="none"
              placeholder="请输入备注"
              v-model="saveMenuData.remarks"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="排序：">
            <el-input-number
              v-model="saveMenuData.sort"
              :min="1"
              label="排序"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="是否有效：">
            <el-radio-group v-model="saveMenuData.enable">
              <el-radio :label="1">有效</el-radio>
              <el-radio :label="0">无效</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddMenu = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmAddMenu"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  menuDelete,
  menuAdd,
  menuList,
  menuUpdate,
} from "@/api/menu";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      formSearch: {
        name: "",
        remarks: "",
        url: "",
      },
      dialogAddMenu: false,
      dialogAddMenuTitle: "",
      pid: 0,
      originList: [
        {
          authority: "",
          enable: 1,
          icon: "",
          name: "根目录",
          label: "根目录",
          id: 0,
          pid: 0,
          remarks: "",
          sort: 1,
          url: "",
          children: [],
        },
      ],
      loading: false,
      searchList: [],
      editMenuId: 0,
      saveMenuData: {
        authority: "",
        enable: 1,
        icon: "",
        name: "",
        pid: 0,
        remarks: "",
        sort: 1,
        url: "",
      },
      rules: {
        authority: [
          { required: true, message: "权限标识不能为空", trigger: "change" },
        ],
        name: [
          { required: true, message: "菜单名称不能为空", trigger: "change" },
        ],
        url: [
          { required: true, message: "功能链接不能为空", trigger: "change" },
        ],
        pid: [{ required: true, message: "菜单不能为空", trigger: "change" }],
      },
    };
  },
  created() {
    this.handleSearch();
  },
  components: {
    Treeselect,
  },
  computed: {
    ...mapState(['authorities']),
  },
  mounted() {},
  methods: {
    // 格式化所有菜单列表
    formatMenuSelect(list) {
      list.forEach((item) => {
        item.label = item.name;
        if (!item.children) {
          item.children = [];
        }
        if (item.children.length > 0) {
          this.formatMenuSelect(item.children);
        }
      });
    },
    // 所有菜单列表，供父菜单选择
    async getOriginList() {
      try {
        let originList = await menuList({
          name: null,
          remarks: null,
          url: null,
        });
        if (!originList) {
          originList = [];
        }
        this.originList[0].children = originList;
        this.formatMenuSelect(this.originList);
      } catch (err) {
        let msg = err.msg ? err.msg : "获取菜单列表发生异常";
        console.error("menuList err: ", err);
        this.msgError(msg);
      }
    },
    // 搜索菜单列表
    async handleSearch() {
      if(!this.authorities.includes('BACK:BACK_T_MENU_PAGE_LIST')){
        return
      }
      try {
        this.loading = true;
        let searchList = await menuList(this.formSearch);
        this.loading = false;
        if (!searchList) {
          searchList = [];
        }
        this.searchList = searchList;
      } catch (err) {
        this.loading = false;
        let msg = err.msg ? err.msg : "获取菜单列表发生异常";
        console.error("menuList err: ", err);
        this.msgError(msg);
      }
    },
    // 点击创建菜单按钮
    handleCreateMenu() {
      this.dialogAddMenu = true;
      this.dialogAddMenuTitle = "创建菜单";
      this.saveMenuData = {
        authority: "",
        enable: 1,
        icon: "",
        name: "",
        pid: 0,
        remarks: "",
        sort: 1,
        url: "",
      };
      this.$nextTick(() => {
        this.$refs["saveMenuData"].resetFields();
      });
      this.getOriginList();
    },
    // 父菜单清空
    handleCloseTree() {
      if (!this.pid) {
        this.pid = "";
      }
    },
    /* 确定创建菜单 */
    handleConfirmAddMenu() {
      this.$refs["saveMenuData"].validate(async (valid) => {
        if (valid) {
          let optName = "";
          if(this._addOrEditFlag){
            return
          }
          this._addOrEditFlag = true
          try {
            if (this.dialogAddMenuTitle == "创建菜单") {
              optName = "创建";
              await menuAdd(this.saveMenuData);
            } else if (this.dialogAddMenuTitle == "编辑菜单") {
              optName = "修改";
              await menuUpdate({ ...this.saveMenuData, id: this.editMenuId });
            }
            this._addOrEditFlag = false
            this.handleSearch();
            this.dialogAddMenu = false;
            this.msgSuccess(`${optName}成功`);
          } catch (err) {
            this._addOrEditFlag = false
            let msg = err.msg ? err.msg : `${optName}菜单发生异常！`;
            console.error("menuAdd err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 处理删除菜单
    handleDeleteMenu(item) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await menuDelete(item.id);
          this.handleSearch();
          this.msgSuccess("删除成功");
        } catch (err) {
          let msg = err.msg ? err.msg : "删除菜单发生异常";
          console.error("menuDelete err: ", err);
          this.msgError(msg);
        }
      });
    },
    // 处理修改
    async handleEditMenu(item) {
      const { authority, enable, icon, sort, remarks, pid, url, name, id } =
        item;
      this.dialogAddMenu = true;
      this.dialogAddMenuTitle = "编辑菜单";
      this.editMenuId = id;
      this.saveMenuData = {
        authority,
        enable,
        icon,
        sort,
        remarks,
        pid,
        url,
        name,
      };
      this.getOriginList();
    },
  },
};
</script>
<style lang='scss' scoped>
.menu-manage {
  height: 100%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search-area {
    font-size: 12px;
    padding: 0 10px 0;
    text-align: left;
  }
  .opt-area {
    padding: 0 10px 10px;
    text-align: right;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
    .m-l-10 {
      margin-left: 10px;
    }
  }
}
</style>